export enum RoleType {
  Super = 'Super',
  SuperAdmin = 'SuperAdmin',
  Admin = 'Admin',
  HealthAdvisor = 'HealthAdvisor',
  Clinician = 'Clinician',
  Patient = 'Patient',
  SupportTeam = 'SupportTeam',
  GPPractitioner = 'GPPractitioner',
  ResourceManager = 'ResourceManager'
}

interface Permission {
  name: string
  code: string
  description: string
}

export enum RoleStatus {
  Active = 'Active',
  Draft = 'Draft',
  Deleted = 'Deleted'
}

export interface Role {
  id?: string
  name?: string
  code: string
  status: RoleStatus
  permissions?: Permission[] | []
  description?: string
  createdAt?: Date
  updatedAt?: Date
}
