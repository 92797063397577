import React, {
  ReactNode,
  createContext,
  useContext,
  FC,
  useState,
  Dispatch,
  SetStateAction,
  useMemo
} from 'react'
import { useAuth } from './AuthProvider'
import useSWR from 'swr'
import { Practitioner } from 'fhir/r4'
import { Source } from '@contexts/AxiosProvider'
import {
  isGPRole,
  isHARole,
  isSuperRole,
  isSuperAdminRole,
  isResourceManagerRole,
  isClinicianRole
} from '@utils/roles'

interface Props {
  children: ReactNode
}

interface ContextValue {
  practitioner: Practitioner | null
  isGP: boolean | null
  isHA: boolean | null
  isClinician: boolean | null
  isSuper: boolean | null
  isSuperAdmin: boolean | null
  isResourceManager: boolean | null
  sidebarOpen: boolean
  setSidebarOpen: Dispatch<SetStateAction<boolean>>
  timelineDate: Date
  setTimelineDate: Dispatch<SetStateAction<Date>>
}

const AppContext = createContext({} as ContextValue)

const AppProvider: FC<Props> = ({ children }) => {
  const { user, roles } = useAuth()
  const shouldFetch = user && user.uid
  const { data } = useSWR(
    shouldFetch ? [`/Practitioner?identifier=${user.uid}`, Source.FHIR] : null
  )
  const [timelineDate, setTimelineDate] = useState<Date>(new Date())

  let isGP = null
  let isHA = null
  let isClinician = null
  let isSuper = null
  let isSuperAdmin = null
  let isResourceManager = null

  if (roles) {
    for (const role of roles) {
      if (isGPRole({ role })) {
        isGP = true
      }
      if (isHARole({ role })) {
        isHA = true
      }
      if (isClinicianRole({ role })) {
        isClinician = true
      }
      if (isSuperRole({ role })) {
        isSuper = true
      }
      if (isSuperAdminRole({ role })) {
        isSuperAdmin = true
      }
      if (isResourceManagerRole({ role })) {
        isResourceManager = true
      }
    }
  }

  const practitioner = useMemo(() => {
    return data && data.entry && data.entry.length ? (data.entry[0].resource as Practitioner) : null
  }, [data])

  const [sidebarOpen, setSidebarOpen] = useState(false)

  const value = {
    practitioner,
    isGP,
    isHA,
    isClinician,
    isSuper,
    isSuperAdmin,
    isResourceManager,
    sidebarOpen,
    setSidebarOpen,
    timelineDate,
    setTimelineDate
  }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

const useAppContext = () => useContext(AppContext)

export { AppProvider, useAppContext }
